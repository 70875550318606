<template>
  <v-container v-bind:class="{'pa-0': $vuetify.breakpoint.smAndDown}">
    <v-row no-gutters>
      <v-col cols="12" class="chat-window">
        <v-card class="chat-wrapper" rounded="0">
          <v-toolbar flat >
            <v-avatar style="overflow:visible">
              <UserImage :user="{ id: adminUserId }" xsmall />
            </v-avatar>
              <v-toolbar-title>
              
              {{ adminName }}

              </v-toolbar-title>
         <!--      <span class="caption" :class="getStatusClass(adminStatus)">
                {{ getStatusText(adminStatus) }}
              </span> -->
              

                <v-spacer/>
            <Configs />
        
          </v-toolbar>
          <v-divider />

          <!-- Scrollable Chat Messages -->
          <v-card-text class="chat-content" ref="chatContent">
            <v-skeleton-loader
              v-if="loading"
              type="list-item-two-line,list-item-two-line,list-item-two-line,list-item-two-line,list-item-two-line"
            />
            <div
              v-for="(message, index) in messages"
              :key="index"
              :class="['message-container', message.fromUser ? 'from-user' : 'from-admin']"
            >
              <div class="message-content">
                <!-- Text Message -->
                <div v-if="message.type === 'text'">
                  <p>{{ message.text }}</p>
                </div>
                <!-- Image Message -->
                <div v-else-if="message.type === 'image'">
                  <v-img
                    :src="message.fileUrl"
                    max-width="200"
                    max-height="200"
                    class="mb-2"
                  ></v-img>
                </div>
                <!-- Video Message -->
                <div v-else-if="message.type === 'video'">
                  <video
                    :src="message.fileUrl"
                    controls
                    width="200"
                    class="mb-2"
                  ></video>
                </div>
                <!-- File Message -->
                <div v-else-if="message.type === 'file'">
                  <v-chip
                    class="mb-2"
                    @click="downloadFile(message.fileUrl)"
                  >
                    <v-icon left>mdi-file</v-icon>
                    {{ message.fileName }}
                  </v-chip>
                </div>
              </div>
            </div>

            <!-- Typing Indicator -->
            <div v-if="isAdminTyping" class="typing-indicator">
              <v-chip class="mb-2">{{ adminName }} está escribiendo...</v-chip>
            </div>
            <v-alert v-else-if="messages.length === 0 && !loading" type="info" color="primary" text>
              No hay mensajes para mostrar. ¡Empieza la conversación!
            </v-alert>
          </v-card-text>

          <v-divider />
          <!-- Message Input Section -->
          <v-card-actions class="message-input">
            <v-progress-linear
              v-if="uploading"
              :value="uploadProgress"
              height="2"
              color="primary"
            ></v-progress-linear>
            <v-text-field
              v-model="newMessage"
              placeholder="Escribe un mensaje..."
              outlined
              dense
              hide-details
              class="rounded-lg"
              @keyup.enter="sendMessage"
              @input="handleTyping"
              :disabled="uploading"
            >
              <template v-slot:append>
                <v-icon @click="triggerFileInput">mdi-paperclip</v-icon>
                <v-icon @click="sendMessage" :color="newMessage ? 'primary' : ''">mdi-send</v-icon>
              </template>
            </v-text-field>
            <input
              type="file"
              ref="fileInput"
              @change="handleFileUpload"
              style="display: none"
            />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  getDatabase,
  ref as dbRef,
  set,
  update,
  push,
  onValue,
  onDisconnect,
  get,
} from 'firebase/database';
import {
  getStorage,
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import UserImage from '@/components/profile/UserImage.vue';
import { getFunctions, httpsCallable } from "firebase/functions";


import Configs from '@/components/notifications/Configs.vue';
export default {
  components: {
    UserImage,
    Configs
  },
  data() {
    return {
      loading: true,
      messages: [],
      newMessage: '',
      userId: null,
      conversationId: null,
      db: null,
      adminStatus: '', 
      adminUserId: '4741184', 
      adminName: '', 
      isAdminTyping: false, 
      typingTimeout: null, 
      uploading: false, 
      uploadProgress: 0, 
      lastSeenUser: 0,
      lastSeenAdmin: 0,
      scrollListenerAttached: false,
    };
  },
  computed: {
    unreadAdminMessagesCount() {
      return this.messages.filter(
        (msg) => !msg.fromUser && msg.timestamp > this.lastSeenUser
      ).length;
    },
  },
  methods: {
    async loadConversation() {
      const userId = this.$store.state.Auth.token.claims.user_id;
      this.userId = userId;
      this.conversationId = userId;
      this.db = getDatabase();

      const conversationRef = dbRef(this.db, `interactions/${userId}`);
      onValue(conversationRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          this.lastSeenUser = data.lastSeenUser || 0;
          this.lastSeenAdmin = data.lastSeenAdmin || 0;
        } else {
          this.lastSeenUser = 0;
          this.lastSeenAdmin = 0;
        }
      });

      const messagesRef = dbRef(this.db, `interactions/${userId}/messages`);
      onValue(messagesRef, (snapshot) => {
        const data = snapshot.val();
        this.messages = [];
        if (data) {
          this.messages = Object.keys(data)
            .map((key) => ({ ...data[key], id: key }))
            .sort((a, b) => a.timestamp - b.timestamp);
        }
        this.loading = false;

        // Scroll to the bottom if possible
        this.$nextTick(() => {
          const chatContent = this.$refs.chatContent;
          if (chatContent) {
            chatContent.scrollTop = chatContent.scrollHeight;

            // Attach a scroll listener once, to check if user views messages
            if (!this.scrollListenerAttached) {
              this.scrollListenerAttached = true;
              chatContent.addEventListener('scroll', this.handleScroll);
            }
          }
        });
      });

      this.listenForAdminStatus();
      this.listenForAdminTyping();
    },
    handleScroll() {
      const chatContent = this.$refs.chatContent;
      // Check if user scrolled near the bottom (e.g., within 20px)
      if (chatContent.scrollHeight - chatContent.scrollTop - chatContent.clientHeight < 20) {
        // User has viewed messages, update lastSeenUser
        update(dbRef(this.db, `interactions/${this.userId}`), {
          lastSeenUser: Date.now(),
        });
      }
    },
    async sendMessage() {
      if (this.newMessage.trim() !== '' && this.userId) {
        const message = {
          text: this.newMessage,
          fromUser: true,
          timestamp: Date.now(),
          type: 'text',
        };

        await this.sendMessageToDatabase(message);
        this.setTypingStatus(false);
        this.newMessage = '';

        // if admin is offline, send call the messageNotification cloud function
        if (this.adminStatus !== 'online') {
          const functions = getFunctions();
          const messageNotification = httpsCallable(functions, "gym/sendMessageToDevices");
          messageNotification({ user_id: this.adminUserId, message: message.text, title: 'Nuevo mensaje',click_action:"/" });
        }
      }
    },
    async sendMessageToDatabase(message) {
      const conversationRef = dbRef(this.db, `interactions/${this.userId}`);
      const messagesRef = dbRef(this.db, `interactions/${this.userId}/messages`);
      const newMessageRef = push(messagesRef);

      const interactionSnapshot = await get(conversationRef);
      const lastMessageText = message.type === 'text' ? message.text : 'Sent a file';

      if (!interactionSnapshot.exists()) {
        await set(conversationRef, {
          type: 'in-app-chat',
          lastMessage: lastMessageText,
          lastMessageDate: Date.now(),
          lastMessageFromUser: message.fromUser ? true : false,
          lastSeenUser: Date.now(),
          lastSeenAdmin: 0,
        });
      } else {
        await update(conversationRef, {
          lastMessage: lastMessageText,
          lastMessageDate: Date.now(),
          lastMessageFromUser: message.fromUser ? true : false,
        });
      }

      await set(newMessageRef, message);

      // If user sends a message, they have definitely seen the chat, so update lastSeenUser
      update(conversationRef, { lastSeenUser: Date.now() });
    },
    setUserOnline(userId) {
      const db = getDatabase();
      const userStatusRef = dbRef(db, `/status/${userId}`);
      set(userStatusRef, {
        name: this.$store.state.Auth.token.claims.name,
        state: 'online',
        lastChanged: Date.now(),
      });
      onDisconnect(userStatusRef).set({
        name: this.$store.state.Auth.token.claims.name,
        state: 'offline',
        lastChanged: Date.now(),
      });
    },
    listenForAdminStatus() {
      const db = getDatabase();
      const adminStatusRef = dbRef(db, `/status/${this.adminUserId}`);

      onValue(adminStatusRef, (snapshot) => {
        const status = snapshot.val();
        if (status) {
          this.adminStatus = status.state || '';
          this.adminName = status.name || 'Coach';
        } else {
          this.adminStatus = '';
          this.adminName = 'Coach';
        }
      });
    },
    listenForAdminTyping() {
      const db = getDatabase();
      const typingRef = dbRef(db, `/typing/${this.conversationId}/admin`);

      onValue(typingRef, (snapshot) => {
        this.isAdminTyping = snapshot.val() || false;
      });
    },
    handleTyping() {
      this.setTypingStatus(true);
      if (this.typingTimeout) {
        clearTimeout(this.typingTimeout);
      }
      this.typingTimeout = setTimeout(() => {
        this.setTypingStatus(false);
      }, 2000);
    },
    setTypingStatus(isTyping) {
      const db = getDatabase();
      const typingRef = dbRef(db, `/typing/${this.conversationId}/user`);
      set(typingRef, isTyping);
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    async handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      this.uploading = true;
      const storage = getStorage();
      const storageReference = storageRef(
        storage,
        `interactions/${this.conversationId}/${Date.now()}_${file.name}`
      );

      const uploadTask = uploadBytesResumable(storageReference, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          this.uploadProgress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.error('File upload error:', error);
          this.uploading = false;
          this.uploadProgress = 0;
        },
        async () => {
          this.uploading = false;
          this.uploadProgress = 0;
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

          let messageType = 'file';
          if (file.type.startsWith('image/')) {
            messageType = 'image';
          } else if (file.type.startsWith('video/')) {
            messageType = 'video';
          }

          const message = {
            fromUser: true,
            timestamp: Date.now(),
            type: messageType,
            fileUrl: downloadURL,
            fileName: file.name,
            fileType: file.type,
          };

          await this.sendMessageToDatabase(message);
        }
      );
    },
    downloadFile(url) {
      window.open(url, '_blank');
    },
    getStatusText(status) {
      return status === 'online' ? 'Online' : '';
    },
    getStatusClass(status) {
      return status === 'online' ? 'text-success' : 'text-secondary';
    },
  },
  async mounted() {
    await this.loadConversation();
    this.setUserOnline(this.$store.state.Auth.token.claims.user_id);
    this.handleScroll();
  },
  beforeDestroy() {
    this.setTypingStatus(false);
    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout);
    }
    // Do not update lastSeenUser here, so that if user leaves without scrolling down, unread messages remain unread.
  },
};
</script>

<style scoped>
.message-container {
  display: flex;
  margin-bottom: 8px;
}

.message-content {
  max-width: 80%;
  padding: 4px 8px;
  border-radius: 8px;
  text-align: left;
  word-wrap: break-word;
}

.from-user .message-content {
  margin-left: auto;
  background-color: grey;
  color: #fff;
}

.from-admin .message-content {
  background-color: #0d5b4e;
  margin-right: auto;
  color: #fff;
}

.message-content p {
  margin: 0;
}

.chat-wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 65px);
}

.chat-header {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.chat-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px;
  height: 100%;
}

.message-input {
  flex-shrink: 0;
}

.text-success {
  color: green;
}

.text-secondary {
  color: grey;
}
</style>
